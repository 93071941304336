// KYCManagement.js
import React, { useState, useEffect } from 'react';
import axiosInstance from './config/axios';
import styles from './styles/KYCmanagement.module.css';

const KYCManagement = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        fetchUsers();
    }, []);
    
    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('access');
            const response = await axiosInstance.get('/api/manage-kyc/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setUsers(response.data);
            setError(null);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    const handleVerification = async (userId, verified) => {
        try {
            const token = localStorage.getItem('access');
            if (verified) {
                // Handle approval
                await axiosInstance.put(`/api/manage-kyc/${userId}/`, 
                    { is_verified: true },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
            } else {
                // Handle decline
                await axiosInstance.delete(`/api/manage-kyc/${userId}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
            
            // Remove user from the list
            setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
            
        } catch (error) {
            console.error('Error updating verification status:', error);
            setError('Failed to update verification status. Please try again.');
        }
    };
    
    if (loading) {
        return <div className={styles.loadingSpinner}>Loading...</div>;
    }
    
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>KYC Verification Management</h2>
            
            {error && <div className={styles.error}>{error}</div>}
            
            {users.length === 0 ? (
                <div className={styles.noUsers}>No pending verifications</div>
            ) : (
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <thead className={styles.tableHeader}>
                            <tr>
                                <th className={styles.tableHeaderCell}>Username</th>
                                <th className={styles.tableHeaderCell}>Email</th>
                                <th className={styles.tableHeaderCell}>Company</th>
                                <th className={styles.tableHeaderCell}>Status</th>
                                <th className={styles.tableHeaderCell}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id} className={styles.tableRow}>
                                    <td className={styles.tableCell}>{user.username}</td>
                                    <td className={styles.tableCell}>{user.email}</td>
                                    <td className={styles.tableCell}>{user.company_name}</td>
                                    <td className={styles.tableCell}>
                                        <span className={`${styles.status} ${styles.statusPending}`}>
                                            Pending
                                        </span>
                                    </td>
                                    <td className={styles.tableCell}>
                                        <div className={styles.buttonContainer}>
                                            <button
                                                onClick={() => handleVerification(user.id, true)}
                                                className={`${styles.button} ${styles.approveButton}`}
                                            >
                                                Approve
                                            </button>
                                            <button
                                                onClick={() => handleVerification(user.id, false)}
                                                className={`${styles.button} ${styles.declineButton}`}
                                            >
                                                Decline
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default KYCManagement;