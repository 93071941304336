import React from 'react';
import { Navbar, Nav, Container, Form, FormControl } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useListings } from './ListingsContext';
import styles from './styles/Navbar.module.css';

const NavigationBar = ({ username, handleLogout, userDetails }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { listings, updateListings, fetchListings } = useListings();

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) {
      fetchListings();
      return;
    }
    const filteredListings = listings.filter(listing => 
      listing.gpu_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.server_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.seller_username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    updateListings(filteredListings);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    fetchListings();
  };

  return (
    <Navbar className={styles.navbar} expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/dashboard" className={styles.navBrand}>
          HPC-Marketplace
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles.navbarToggler} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`me-auto ${styles.navLinks}`}>
            <Nav.Link as={Link} to="/dashboard" className={styles.navLink}>Home</Nav.Link>
            
            {userDetails && !userDetails.is_superuser && ( 
              <Nav.Link as={Link} to="/my-listings" className={styles.navLink}>My Listings</Nav.Link>
            )}
            {userDetails && userDetails.is_superuser && ( 
              <>
                <Nav.Link as={Link} to="/buyers" className={styles.navLink}>Buyers</Nav.Link>
                <Nav.Link as={Link} to="/sellers" className={styles.navLink}>Sellers</Nav.Link>
                <Nav.Link as={Link} to="/kyc-management" className={styles.navLink}>Pending Users</Nav.Link>
              </>
            )}
          </Nav>
          <Form className={`d-flex ${styles.searchForm}`} onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Search listings..."
              className={styles.searchInput}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                type="button"
                className={styles.clearButton}
                onClick={handleSearchClear}
              >
                Clear
              </button>
            )}
            <button type="submit" className={styles.searchButton}>
              Search
            </button>
          </Form>
          <Nav className={styles.userSection}>
            <Navbar.Text className={styles.userText}>
              {username}
            </Navbar.Text>
            <button 
              onClick={handleLogout}
              className={styles.logoutButton}
            >
              Logout
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;