import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListings } from './ListingsContext';
import styles from './styles/ListingCard.module.css';
import axiosInstance from './config/axios';

const formatValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(', ') : 'Not Provided';
  }
  if (typeof value === 'object' && value !== null) {
    if ('length' in value && 'width' in value && 'height' in value) {
      return `${value.length || 0} × ${value.width || 0} × ${value.height || 0} inches`;
    }
    return 'Not Provided';
  }
  return value || 'Not Provided';
};

const DetailRow = ({ label, value }) => (
  <div className={styles.detailRow}>
    <span className={styles.label}>{label}:</span>
    <span className={styles.value}>{value}</span>
  </div>
);

const ListingCard = ({ listing, onBidClick }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('access');
        if (!token) return;
        const response = await axiosInstance.get('/api/user-details/', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserDetails(response.data);
      } catch (err) {
        console.error('Error fetching user details:', err);
      }
    };
    fetchUserDetails();
  }, []);

  const handleViewDetails = () => {
    navigate(`/detail/${listing.id}`, { state: { listing } });
  };

  const handleBidClick = () => {
    if (!userDetails?.is_verified) {
      alert('You need to complete KYC verification before placing bids. Please contact support.');
      return;
    }
    onBidClick(listing);
  };

  const basicDetails = (
    <>
      <DetailRow label="Server Name" value={listing.server_name} />
      <DetailRow label="Condition" value={listing.condition} />
      <DetailRow label="Chassis" value={listing.chassis} />
      <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
    </>
  );

  const expandedDetails = (
    <>
      {basicDetails}
      <DetailRow label="Number of Nodes" value={listing.number_of_nodes} />
      <DetailRow label="GPUs per Node" value={listing.number_of_gpus_per_node} />
      <DetailRow label="Processor Type" value={listing.processor_type} />
      <DetailRow label="Memory" value={`${listing.memory} GB`} />
      <DetailRow label="Operating Systems" value={listing.operating_systems} />
    </>
  );

  return (
    <div 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
      className={styles.card}
      style={{
        height: isHovered ? 'auto' : '320px',
        zIndex: isHovered ? 2 : 1,
        position: 'relative'
      }}
    >
      <div className={styles.cardContent}>
        <h3 className={styles.title}>{listing.gpu_type}</h3>
        
        <div className={styles.details}>
          {isHovered ? expandedDetails : basicDetails}
        </div>

        <div className={styles.footer}>
          <div className={styles.timePrice}>
            <div className={styles.timeLeft}>Time Left: {listing.time_left}</div>
            <div className={styles.price}>Ask Price: ${listing.ask_price}</div>
            <div className={styles.bids}>
              {listing.current_highest_bid ? 
                `Highest Bid: $${listing.current_highest_bid}${listing.highest_bidder_username ? ` by ${listing.highest_bidder_username}` : ''}` : 
                'No bids yet'
              }
            </div>
          </div>

          <div className={styles.buttonContainer}>
            {!userDetails?.is_verified ? (
              <button className={`${styles.button} ${styles.primaryButton}`}>
                KYC Required
              </button>
            ) : (
              <button 
                onClick={handleBidClick}
                className={`${styles.button} ${styles.primaryButton}`}
              >
                Bid
              </button>
            )}
            <button 
              onClick={handleViewDetails}
              className={styles.button}
            >
              Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;