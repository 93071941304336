import React, { useState, useEffect } from 'react';
import './App.css';
import Register from './components/Register';
import Login from './components/login';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import GPUListingForm from './components/CreateGpuListing';
import DetailPage from './components/Detail';
import NavigationBar from './components/Navbar';
import { ListingsProvider } from './components/ListingsContext';
import MyListingsPage from './components/MyListingsPage';
import EditListing from './components/EditListing';
import Sellers from './components/Sellers';
import Buyers from './components/Buyers';
import Home from './components/Home';
import './components/styles/global.css';
import Footer from './components/Footer';
import KYCManagement from './components/KYCmanagement';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const token = localStorage.getItem('access');

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
      setUsername(localStorage.getItem('username') || '');
      setUserId(localStorage.getItem('userId') || null);
    }
  }, []);

  const handleLogout = () => {
    console.log('Logging out');
    localStorage.removeItem('access');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    setIsLoggedIn(false);
    setUsername('');
    setUserId(null);
    window.location.href = '/';
  };

  const ProtectedRoute = ({ children }) => {
    if (!isLoggedIn && !token) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <ListingsProvider>
        {isLoggedIn && (
          <NavigationBar 
            username={username} 
            handleLogout={handleLogout}
            userDetails={userDetails}
          />
        )}
        <Routes>
          <Route path="/" element={
            isLoggedIn ? 
            <Navigate to="/dashboard" /> : 
            <Home />
            }
          />
          <Route path="/login" element={
            <Login 
              setIsLoggedIn={setIsLoggedIn} 
              setUsername={setUsername} 
              setUserId={setUserId}
            />
          } />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/dashboard" 
            element={
              (isLoggedIn || token) ? 
              <Dashboard 
                isLoggedIn={isLoggedIn} 
                username={username} 
                handleLogout={handleLogout} 
                setUserDetails={setUserDetails}
                userDetails={userDetails}
              /> :
              <Navigate to="/login" />
            } 
          />
          <Route 
            path="/my-listings" 
            element={
              (isLoggedIn || token) ? 
              <MyListingsPage username={username} /> : 
              <Navigate to="/login" />
            } 
          />
          <Route 
            path="/sellers" 
            element={
              <ProtectedRoute>
                <Sellers />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/buyers" 
            element={
              <ProtectedRoute>
                <Buyers />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/kyc-management" 
            element={
              <ProtectedRoute isAdmin>
                <KYCManagement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/create-listing" 
            element={isLoggedIn ? <GPUListingForm /> : <Navigate to="/login" />} 
          />
          <Route path="/detail/:id" element={<DetailPage />} />
          <Route 
            path="/edit-listing/:id" 
            element={isLoggedIn ? <EditListing /> : <Navigate to="/login" />} 
          />
        </Routes>
        <Footer />
      </ListingsProvider>
    </BrowserRouter>
  );
}

export default App;