import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from './assets/logo.jpg';


const Footer = () => {
  return (
    <footer className="bg-[rgba(17,24,39,0.95)] backdrop-blur-lg border-t border-white/10 text-white pt-8 pb-4 mt-auto">
      <div className="max-w-7xl mx-auto px-8">
        <div className="flex flex-col md:flex-row justify-between gap-8">
          {/* Left Section */}
          <div className="flex-1 max-w-md">
            <div className="flex items-center gap-3 mb-2">
              <img src={logoImage} alt="BitOoda Logo" className="h-8 w-8" />
              <span className="text-xl font-semibold">Fp8</span>
            </div>
            <p className="text-white/80 mb-2 leading-relaxed">
              A next-generation platform for the rapidly growing high performance compute ecosystem.
            </p>
            <p className="text-white/70 text-sm mb-4">
              Address: 55 Arch Street, Greenwich, CT 06830
            </p>
            <div className="flex gap-4">
              <a 
                href="https://twitter.com/BitOodaCompute" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
                </svg>
              </a>
              <a 
                href="https://www.linkedin.com/company/bitooda/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                  <rect x="2" y="9" width="4" height="12" />
                  <circle cx="4" cy="4" r="2" />
                </svg>
              </a>
            </div>
          </div>

          {/* Right Section */}
          <div className="flex gap-16">
            <div className="flex flex-col gap-2">
              <Link to="https://www.bitooda.io/privacy-policy" className="text-white/80 hover:text-white text-sm transition-colors no-underline">
                Privacy Policy
              </Link>
              <Link to="https://www.bitooda.io/terms-of-use" className="text-white/80 hover:text-white text-sm transition-colors no-underline">
                Terms of Use
              </Link>
              <a href="mailto:info@bitooda.io" className="text-white/80 hover:text-white text-sm transition-colors no-underline">
                  Contact
                </a>
              <Link to="https://www.bitooda.io/disclosures" className="text-white/80 hover:text-white text-sm transition-colors no-underline">
                Disclosures
              </Link>
            </div>
          </div>
        </div>

        <div className="text-center text-white/60 text-sm mt-6 pt-4 border-t border-white/10">
          © 2024 Fp8
        </div>
      </div>
    </footer>
  );
};

export default Footer;