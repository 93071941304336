// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ListingCard from './ListingCard';
import BidModal from './BidModal';
import { useListings } from './ListingsContext';
import styles from './styles/Dashboard.module.css';
import axiosInstance from './config/axios';

const Dashboard = ({ username, handleLogout, isLoggedIn, setUserDetails }) => {
  const [userDetails, setLocalUserDetails] = useState(null);
  const { listings, updateListings, updateListing } = useListings();
  const [error, setError] = useState('');
  const [showBidModal, setShowBidModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const navigate = useNavigate();

  // Styles for KYC banner
  const kycBannerStyle = {
    backgroundColor: '#fff3cd',
    color: '#856404',
    padding: '12px 20px',
    borderBottom: '1px solid #ffeeba',
    marginBottom: '20px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    zIndex: 1000
  };

  const kycLinkStyle = {
    color: '#533f03',
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '5px'
  };
  
  const resetListings = async () => {
    await fetchListings();
  };

  const fetchListings = async () => {
    try {
      const token = localStorage.getItem('access');
      
      if (!token) {
        setError('Authentication token not found. Please log in again.');
        return;
      }

      const response = await axiosInstance.get('/api/gpu-listings/', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status < 500;
        }
      });

      if (response.status === 401) {
        setError('Session expired. Please log in again.');
        return;
      }

      if (response.status !== 200) {
        throw new Error(`Server responded with status code ${response.status}`);
      }

      const activeListings = response.data.filter(listing => {
        const endTime = new Date(listing.bid_end_time);
        return endTime > new Date();
      });
      setError('');
      
      updateListings(activeListings);

    } catch (err) {
      console.error('Error fetching listings:', err);
      setError('Failed to fetch GPU listings. Please check your connection and try again.');
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('access');
        const response = await axiosInstance.get('/api/user-details/', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        setLocalUserDetails(response.data);
        setUserDetails(response.data);
      } catch (err) {
        console.error('Error fetching user details:', err);
      }
    };

    fetchUserDetails();
    fetchListings();

    const interval = setInterval(fetchListings, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleCreateListing = () => {
    if (!userDetails?.is_verified) {
      alert('You need to complete KYC verification before creating listings. Please contact support.');
      return;
    }
    navigate('/create-listing');
  };

  const handleListingCreated = () => {
    fetchListings();
  };

  const handleRetry = () => {
    setError('');
    fetchListings();
  };

  const handleBidClick = (listing) => {
    setSelectedListing(listing);
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
    setSelectedListing(null);
  };

  const handleBidPlaced = (updatedListing) => {
    updateListing(updatedListing);
  };

  const handleKYCClick = () => {
    window.open('https://kyc.argos-solutions.io/apply/230948287428064', '_blank');
  };

  return (
    <>
      {/* KYC Warning Banner */}
      {userDetails && !userDetails.is_verified && (
        <div style={kycBannerStyle}>
          ⚠️ Complete KYC verification to place bids and create GPU listings. 
          <a 
            href="https://kyc.argos-solutions.io/apply/230948287428064" 
            target="_blank" 
            rel="noopener noreferrer"
            style={kycLinkStyle}
          >
            Complete KYC
          </a>
        </div>
      )}

      <div className={styles.container}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className={styles.title}>Welcome {username}</h1>
          <div>
            {(userDetails?.purpose === 'seller' || userDetails?.is_superuser) && (
              <>
                <button 
                  className={`btn ${!userDetails?.is_verified ? 'btn-secondary' : 'btn-primary'} me-2`}
                  onClick={handleCreateListing}
                  disabled={!userDetails?.is_verified}
                >
                  {!userDetails?.is_verified ? 'KYC Required' : 'Create New Listing'}
                </button>
                {!userDetails?.is_verified && (
                  <div className="text-danger mt-1" style={{ fontSize: '0.8rem' }}>
                    KYC verification required to create listings
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {error && (
          <Alert variant="danger" className="mb-4">
            {error}
            <div className="mt-2">
              {error.includes('Please log in again') ? (
                <button 
                  className="btn btn-outline-primary btn-sm"
                  onClick={handleLogout}
                >
                  Log In
                </button>
              ) : (
                <button 
                  className="btn btn-outline-primary btn-sm"
                  onClick={handleRetry}
                >
                  Retry
                </button>
              )}
            </div>
          </Alert>
        )}

        <div className={styles.listingsWrapper}>
          {listings.length === 0 ? (
            <div className="text-center mt-4">
              <h3>No active listings available</h3>
            </div>
          ) : (
            <div className={styles.listingsGrid}>
              {listings.map((listing) => (
                <div className={styles.cardWrapper} key={listing.id}>
                  <ListingCard 
                    listing={listing}
                    onBidClick={handleBidClick}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <BidModal
          show={showBidModal}
          handleClose={handleBidModalClose}
          listing={selectedListing}
          onBidPlaced={handleBidPlaced}
        />
      </div>
    </>
  );
};

export default Dashboard;